interface Currency {
  precision: number;
  thousandSeparator: string;
  decimalSeparator: string;
  symbol: string;
  symbolPlacement: string;
}

interface windowOptions {
  url?: string;
  title?: string;
  width?: number;
  height?: number;
  left?: number;
  top?: number;
}

export default {
  formatMoney(amount: any, currency: Currency) {
    if (!currency) {
      currency = {
        precision: 2,
        thousandSeparator: ",",
        decimalSeparator: ".",
        symbol: "$",
        symbolPlacement: "before",
      };
    }

    amount = amount / 100;

    let { precision } = currency;
    const {
      decimalSeparator,
      thousandSeparator,
      symbol,
      symbolPlacement,
    } = currency;

    try {
      precision = Math.abs(precision);
      precision = isNaN(precision) ? 2 : precision;

      const negativeSign = amount < 0 ? "-" : "";

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(precision))
      ).toString();
      const j = i.length > 3 ? i.length % 3 : 0;

      const moneySymbol = `<span style="font-family: sans-serif">${symbol}</span>`;

      if (symbolPlacement == "before") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return (
          moneySymbol +
          " " +
          negativeSign +
          (j ? i.substr(0, j) + thousandSeparator : "") +
          i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousandSeparator) +
          (precision
            ? decimalSeparator +
              Math.abs(amount - parseInt(i))
                .toFixed(precision)
                .slice(2)
            : "")
        );
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return (
          negativeSign +
          (j ? i.substr(0, j) + thousandSeparator : "") +
          i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousandSeparator) +
          (precision
            ? decimalSeparator +
              Math.abs(amount - parseInt(i))
                .toFixed(precision)
                .slice(2)
            : "") +
          " " +
          moneySymbol
        );
      }
    } catch (e) {
      console.log(e);
    }
  },
  checkValidUrl(url: string) {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );

    return !!pattern.test(url);
  },
  openWindow(
    url: string | Record<string, unknown>,
    title: string,
    options: windowOptions = {}
  ) {
    if (typeof url === "object") {
      options = url;
      url = "";
    }
    options = { url, title, width: 600, height: 720, ...options };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenLeft;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screenTop;
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      window.screen.width;
    const height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      window.screen.height;
    if (options.width) {
      options.left = width / 2 - options.width / 2 + dualScreenLeft;
    }
    if (options.height) {
      options.top = height / 2 - options.height / 2 + dualScreenTop;
    }
    const optionsStr = Object.keys(options)
      .reduce((acc, key) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        acc.push(`${key}=${options[key]}`);
        return acc;
      }, [])
      .join(",");
    const newWindow = window.open(url, title, optionsStr);
    if (document.hasFocus() && newWindow) {
      newWindow.focus();
    }
    return newWindow;
  },
  includeScript(URL: string, callback: any) {
    const documentTag = document;
    const tag = "script";
    const object = documentTag.createElement(tag);
    const scriptTag = documentTag.getElementsByTagName(tag)[0];
    object.src = "//" + URL;
    if (callback) {
      object.addEventListener(
        "load",
        function(e) {
          callback(null, e);
        },
        false
      );
    }
    if (scriptTag.parentNode) {
      scriptTag.parentNode.insertBefore(object, scriptTag);
    }
  },
  cleanData(obj: any) {
    for (const propName in obj) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (
        Object.prototype.hasOwnProperty.call(obj, propName) &&
        (obj[propName] === null || obj[propName] === "")
      ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete obj[propName];
      }
    }
    return obj;
  },
};

export const getValues = function(item: any, key: string) {
  return key.split(".").reduce((o, i) => {
    if (Array.isArray(o)) {
      const items: Array<string> = [];
      o.forEach((elem) => {
        items.push(elem[i]);
      });
      return items.join(", ");
    }
    return o ? o[i] : "";
  }, item);
};
