<template>
  <button :id="id" type="submit" :name="name" class="btn" :disabled="loading">
    <Spinner v-if="loading" />
    {{ title }}
  </button>
</template>

<script>
import Spinner from "@/components/UI/Spinner.vue";
export default {
  name: "ButtonLoading",
  components: {
    Spinner,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "submit",
    },
    id: {
      type: String,
      default: "submit",
    },
  },
};
</script>

<style lang="scss" scoped></style>
