<template>
  <component :is="layout">
    <div class="row fadeInUp" role="main">
      <div class="col-12 col-md-3">
        <ConfigurationNav />
      </div>
      <div class="col-12 col-md-9">
        <div class="card border-0 shadow-sm">
          <div class="card-header">
            <h4>
              Create a filter
            </h4>
          </div>
          <div class="card-body">
            <div class="text-center">
              <p>Select filter type</p>
              <div class="d-flex justify-content-center">
                <button
                  class="btn mr-3"
                  :class="
                    form.type === 'order' ? 'btn-primary' : 'btn-outline-primary'
                  "
                  @click="form.type = 'order'"
                >
                  Orders
                </button>
                <button
                  class="btn"
                  :class="
                    form.type === 'customer' ? 'btn-primary' : 'btn-outline-primary'
                  "
                  @click="form.type = 'customer'"
                >
                  Customers
                </button>
              </div>
            </div>
            <div class="form-row">
              <div
                class="form-group col-12 required"
                :class="{ 'is-invalid': errors.has('name') }"
              >
                <label for="name" class="control-label text-capitalize"
                  >Filter name</label
                >
                <input
                  id="name"
                  v-model="form.name"
                  v-validate="{ required: true }"
                  type="text"
                  name="name"
                  class="form-control"
                  placeholder="VIP customers"
                  autocomplete="off"
                />
                <span v-show="errors.has('name')" class="invalid-feedback">{{
                  errors.first("name")
                }}</span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-12">
                <p class="mb-0">
                  Conditions:
                </p>
              </div>
              <div
                v-if="Object.keys(form.conditions).length === 0"
                class="form-group col-12"
              >
                <div
                  class="btn btn-block btn-outline-secondary"
                  @click="addCondition"
                >
                  Add condition
                </div>
              </div>
            </div>
            <div v-if="form.conditions.length > 1">
              <div class="text-center">
                <p>Select how the conditions should be evaluated</p>
                <div class="d-flex justify-content-center">
                  <button
                    class="btn mr-3"
                    :class="
                      form.conditions_group === 'AND'
                        ? 'btn-primary'
                        : 'btn-outline-primary'
                    "
                    @click="form.conditions_group = 'AND'"
                  >
                    And
                  </button>
                  <button
                    class="btn"
                    :class="
                      form.conditions_group === 'OR'
                        ? 'btn-primary'
                        : 'btn-outline-primary'
                    "
                    @click="form.conditions_group = 'OR'"
                  >
                    Or
                  </button>
                </div>
                <!-- add description when condition is selected -->
                <br />
                <div class="d-flex justify-content-center">
                  <div v-if="form.conditions_group === 'AND'">
                    <p>
                      All conditions should apply for this automation to run.
                    </p>
                  </div>
                  <div v-else-if="form.conditions_group === 'OR'">
                    <p>Any of these conditions will make the automation run.</p>
                  </div>
                  <div v-else>
                    <p />
                  </div>
                </div>
              </div>
            </div>
            <div v-for="(condition, index) in form.conditions" :key="index">
              <condition
                :condition="condition"
                :index="index"
                :simple="true"
                :model="form.type"
                @deleteCondition="deleteCondition"
              />
              <div
                v-if="index + 1 == Object.keys(form.conditions).length"
                class="btn btn-block btn-outline-secondary"
                @click="addCondition"
              >
                Add another condition
              </div>
            </div>
          </div>
          <div class="card-footer bg-white">
            <ButtonLoading
              :loading="loading"
              :title="'Save filter'"
              :class="'btn btn-secondary float-right'"
              v-on:click.native="submitForm"
            />
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import Default from "@/layouts/default.vue";
import Condition from "@/components/Automation/Condition.vue";
import ConfigurationNav from "@/components/Nav/ConfigurationNav.vue";
import { postToApi } from "@/helpers/apiConnection";
import utilities from "@/helpers/utilities";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";

export default {
  components: {
    Condition,
    Default,
    ConfigurationNav,
    ButtonLoading,
  },
  data: function() {
    return {
      layout: "Default",
      loading: false,
      hasError: false,
      form: {
        name: null,
        type: "order",
        conditions_group: "AND",
        conditions: [],
        organization_id: 1,
      },
      step_status: {
        key: "create_filter",
        status: true,
      },
    };
  },
  methods: {
    async submitForm() {
      this.loading = true;

      this.$store.dispatch("CHECK_ONBOARDING_STEP", this.step_status);

      this.$validator.validateAll().then(async (result) => {
        if (result) {
          const data = utilities.cleanData(this.form);
          await postToApi("/segment", data).catch((error) => {
            Object.keys(error.response.data.errors).forEach((key) => {
              this.$validator.errors.add({
                field: key,
                msg: error.response.data.errors[key][0],
              });
            });
            this.hasError = true;
            window.Bus.$emit("flash-message", {
              text: "error main",
              type: "error",
            });
          });
          if (!this.hasError) {
            this.$router.push({ name: "organization.segment.index" });
          }
        }
        this.loading = false;
        this.hasError = false;
      });
    },
    deleteCondition(index) {
      this.$delete(this.form.conditions, index);
    },
    addCondition() {
      this.form.conditions.push({
        field: "total",
        type: "order",
        operator: "==",
        value: "",
      });
    },
  },
};
</script>
