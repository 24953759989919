<template>
  <div class="card card-bleed border-bottom border-bottom-md-0 shadow-light-lg">
    <div id="sidenavCollapse" class="collapse d-md-block">
      <div class="card-body">
        <h6 class="font-weight-bold text-uppercase mb-3">
          Configuration & overview
        </h6>
        <ul class="card-list list text-gray-700 mb-6">
          <li class="list-item" v-if="hasPermission('view-recipes')">
            <router-link
              class="list-link text-reset"
              :to="{ name: 'recipes.index' }"
            >
              Automation recipes
            </router-link>
          </li>
          <li class="list-item" v-if="hasPermission('view-status')">
            <router-link
              class="list-link text-reset"
              :to="{ name: 'organization.status' }"
            >
              Order statuses
            </router-link>
          </li>
          <li class="list-item" v-if="hasPermission('view-tags')">
            <router-link
              class="list-link text-reset"
              :to="{ name: 'organization.tags' }"
            >
              Tags
            </router-link>
          </li>
          <li class="list-item" v-if="hasPermission('view-activity')">
            <router-link
              class="list-link text-reset"
              :to="{ name: 'automations.activity' }"
            >
              Automation activity
            </router-link>
          </li>
        </ul>
        <h6
          class="font-weight-bold text-uppercase mb-3"
          v-if="hasPermission('view-print-templates')"
        >
          Templates
        </h6>
        <ul class="card-list list text-gray-700 mb-6">
          <li class="list-item">
            <router-link
              class="list-link text-reset"
              :to="{ name: 'organization.display.index' }"
            >
              Template design
            </router-link>
          </li>
          <li class="list-item" v-if="hasPermission('view-email-templates')">
            <router-link
              class="list-link text-reset"
              :to="{ name: 'organization.email.template' }"
            >
              Email templates
            </router-link>
          </li>
          <li class="list-item" v-if="hasPermission('view-forms')">
            <router-link
              class="list-link text-reset"
              :to="{ name: 'form.list' }"
            >
              Questionnaires
            </router-link>
          </li>
        </ul>
        <h6 class="font-weight-bold text-uppercase mb-3">
          Integrations & Export
        </h6>
        <ul class="card-list list text-gray-700 mb-6">
          <li class="list-item" v-if="hasPermission('view-integrations')">
            <router-link
              class="list-link text-reset"
              :to="{ name: 'organization.integrations.index' }"
            >
              Integration overview
            </router-link>
          </li>
          <li class="list-item" v-if="hasPermission('view-email-config')">
            <router-link
              class="list-link text-reset"
              :to="{ name: 'organization.email' }"
            >
              Email smtp settings
            </router-link>
          </li>
          <li class="list-item" v-if="hasPermission('view-exports')">
            <router-link
              class="list-link text-reset"
              :to="{ name: 'export.index' }"
            >
              Exports
            </router-link>
          </li>
        </ul>
        <h6 class="font-weight-bold text-uppercase mb-3">
          Advanced
        </h6>
        <ul class="card-list list text-gray-700 mb-0">
          <li class="list-item" v-if="hasPermission('view-meta-fields')">
            <router-link
              class="list-link text-reset"
              :to="{ name: 'meta.fields.index' }"
            >
              Meta fields
            </router-link>
          </li>
          <li class="list-item">
            <router-link
              class="list-link text-reset"
              :to="{ name: 'organization.segment.index' }"
            >
              Filters
            </router-link>
          </li>
          <li class="list-item">
            <router-link
              class="list-link text-reset"
              :to="{ name: 'configuration.view' }"
            >
              Views
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Permission from "@/utils/permission";
export default {
  name: "OrganizationNav",
  extends: Permission,
  computed: {
    viewTeamAndUsers() {
      return (
        this.hasPermission("view-users") ||
        this.hasPermission("view-roles") ||
        this.hasPermission("view-stores")
      );
    },
    viewEmailMenus() {
      return (
        this.hasPermission("view-email-config") ||
        this.hasPermission("view-email-templates")
      );
    },
  },
};
</script>

<style scoped>
.text-reset:hover,
.list-item .active {
  color: #2db6fc !important;
}
</style>
